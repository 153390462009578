import React from "react";
import { concatClassNames as cn } from "@sys42/utils";
import { Headline2Sans, ButtonLink } from "@userbrain/website-ui";
import * as styles from "./styles.module.css";

export function Testimonials(props) {
  const { className, title, titleWidth, testimonials } = props;

  const testimonialRows = [];

  // split testimonials into alternating rows of 1 or 2 items

  for (let i = 0, countThisRow; i < testimonials.length; i += countThisRow) {
    if (testimonialRows.length % 2 === 0) {
      countThisRow = testimonials.length > 3 ? 1 : 2;
    } else {
      countThisRow = 2;
    }

    testimonialRows.push(testimonials.slice(i, i + countThisRow));
  }

  return (
    <div className={cn(className, styles.testimonials)}>
      {title && (
        <Headline2Sans
          className={styles.title}
          style={{ maxWidth: titleWidth }}
        >
          {title}
        </Headline2Sans>
      )}
      {testimonialRows.map((row, rowIndex) => (
        <div className={styles.testimonialsList} key={rowIndex}>
          {row.map((item, index) => (
            <figure className={styles.testimonialsListItem} key={index}>
              <div className={styles.bubble}>
                {item.title && (
                  <h3 className={styles.quoteTitle}>{item.title}</h3>
                )}
                <blockquote className={styles.blockquote} cite={item.viaHref}>
                  {item.text}
                </blockquote>
              </div>

              <figcaption className={styles.figcaption}>
                {item.image ? (
                  <img
                    className={styles.image}
                    src={item.image}
                    alt={item.alt}
                  />
                ) : (
                  <div className={cn(styles.image, styles.image_placeholder)}>
                    {item.name
                      .split(" ")
                      .map((word) => word[0])
                      .join("")}
                  </div>
                )}

                <div>
                  <div className={styles.name}>{item.name}</div>
                  <div className={styles.position}>{item.position}</div>
                  {item.viaName && item.viaHref && (
                    <div className={styles.via}>
                      via{" "}
                      <cite>
                        <ButtonLink
                          hideArrow
                          customTag={"a"}
                          href={item.viaHref}
                          rel={"noopener noreferrer"}
                          target={"_blank"}
                        >
                          {item.viaName}
                        </ButtonLink>
                      </cite>
                    </div>
                  )}
                </div>
              </figcaption>
            </figure>
          ))}
        </div>
      ))}
    </div>
  );
}
