import React, { useState } from "react";
import { concatClassNames as cn } from "@sys42/utils";
import {
  ButtonLink,
  ButtonPrimaryLarge,
  HighlightedText,
  addUtmParams,
  urlBookADemo,
  advertedPoolSize,
} from "@userbrain/website-ui";

import * as styles from "./styles.module.css";

export function GetStartedBox(props) {
  const {
    className,
    contactUs,
    title,
    titleWidth,
    textBottom,
    c2aButtonLabel,
  } = props;

  const [email, setEmail] = useState("");

  function handleChangeEmail(e) {
    setEmail(e.target.value);
  }

  const theTitle = title || (
    <>
      Get started & tap into our pool of{" "}
      <HighlightedText cursor>{advertedPoolSize} testers</HighlightedText>{" "}
      today.
    </>
  );
  const theTitleWidth = title ? titleWidth : "12em";

  return (
    <section className={cn(className, styles.getStartedBox)}>
      <div>
        <h1 style={{ maxWidth: theTitleWidth }}>{theTitle}</h1>
        {contactUs ? (
          <ButtonPrimaryLarge
            className={styles.contactButton}
            customTag={"a"}
            href={"mailto:sales@userbrain.com"}
          >
            Contact us
          </ButtonPrimaryLarge>
        ) : (
          <>
            <form
              className={styles.inputWithButton}
              action={`https://dashboard.userbrain.com/register/${encodeURI(
                email
              )}`}
              method={"get"}
            >
              <input
                type="email"
                value={email}
                onChange={handleChangeEmail}
                placeholder="Enter your email"
                required
              />
              <ButtonPrimaryLarge type="submit">
                {c2aButtonLabel || "Start free trial"}
              </ButtonPrimaryLarge>
            </form>
            <div style={{ marginTop: "1rem" }}>
              or{" "}
              <ButtonLink
                customTag={"a"}
                href={addUtmParams(urlBookADemo, "website", "getstartedbox")}
              >
                Book a demo
              </ButtonLink>
            </div>
          </>
        )}
      </div>
      {textBottom && <div className={styles.textBottom}>{textBottom}</div>}
    </section>
  );
}
