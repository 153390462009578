import React from "react";
import { Link } from "gatsby-plugin-intl";
import { concatClassNames as cn } from "@sys42/utils";
import {
  ButtonPrimary,
  Button,
  Tooltip,
  addUtmParams,
  urlBookADemo,
  advertedPoolSize,
} from "@userbrain/website-ui";

import * as styles from "./styles.module.css";

import { ToggleBillingCycle } from "./toggleBillingCycle";

import { getCreateAccountUrl, usePrices } from "../../helpers";

import imgFree from "./free.png";
import imgStarter from "./starter.png";
import imgPro from "./pro.png";
import imgAgency from "./agency.png";
import imageBulletCancelAnytime from "./bulletFreeTrial.png";
import imageBulletOk from "./bulletOk.png";
import imageBulletLifebuoy from "./bulletLifebuoy.png";

export function PricingFull2022(props) {
  const {
    className,
    currency,
    currencySymbol,
    billingCycle,
    onChangeBillingCycle,
    ...containerProps
  } = props;

  const showAnnualPrice = billingCycle === "annual";
  const periodLabel = showAnnualPrice ? "/ mo. billed yearly" : "per month";

  const {
    priceProYearly,
    priceProMonthly,
    priceStarterYearly,
    priceStarterMonthly,
    priceAgencyYearly,
    priceAgencyMonthly,
  } = usePrices();

  return (
    <div className={cn(className, styles.pricingFull)} {...containerProps}>
      <ToggleBillingCycle
        className={styles.toggleBillingCycle}
        onChange={onChangeBillingCycle}
        value={billingCycle}
      />

      <div className={styles.plans}>
        <div>
          <Plan
            name="Free"
            imageSrc={imgFree}
            imageAlt="Hand pointing towards the top right"
            currencySymbol={currencySymbol}
            price={0}
            isFreePlan={true}
            periodLabel={periodLabel}
            buttonHref={getCreateAccountUrl("free")}
            buttonVariation="secondary"
            buttonText="Create account"
          >
            <FeaturesFree />
          </Plan>

          <Plan
            name="Starter"
            imageSrc={imgStarter}
            imageAlt="Hand pressing a buzzer"
            currencySymbol={currencySymbol}
            price={showAnnualPrice ? priceStarterYearly : priceStarterMonthly}
            periodLabel={periodLabel}
            buttonHref={getCreateAccountUrl("starter")}
          >
            <FeaturesPaid
              billingCycle={billingCycle}
              testersPerMonth={3}
              collaboratorCount={5}
            />
          </Plan>
        </div>
        <div>
          <Plan
            name="Pro"
            isMostPopular
            imageSrc={imgPro}
            imageAlt="A spaceshuttle on turquoise background"
            currencySymbol={currencySymbol}
            price={showAnnualPrice ? priceProYearly : priceProMonthly}
            periodLabel={periodLabel}
            buttonHref={getCreateAccountUrl("pro")}
          >
            <FeaturesPaid
              billingCycle={billingCycle}
              testersPerMonth={10}
              collaboratorCount={20}
            />
          </Plan>

          <Plan
            name="Agency"
            imageSrc={imgAgency}
            imageAlt="Old Apple computer with a pixalated open mail envelope."
            currencySymbol={currencySymbol}
            price={showAnnualPrice ? priceAgencyYearly : priceAgencyMonthly}
            periodLabel={periodLabel}
            buttonHref={getCreateAccountUrl("agency")}
          >
            <FeaturesPaid
              billingCycle={billingCycle}
              testersPerMonth={30}
              collaboratorCount={50}
            />
          </Plan>
        </div>
      </div>

      <div className={styles.bullets}>
        <Bullet
          imgSrc={imageBulletOk}
          imgAlt="Line drawing of a hand making an OK sign"
          title="100% satisfaction guarantee"
          description="If you're not happy with one of our testers, you'll get a new one at no extra cost."
        />
        <Bullet
          imgSrc={imageBulletCancelAnytime}
          imgAlt="Line drawing of a mouse pointer clicking"
          title="Change or cancel anytime"
          description={
            "Upgrade, downgrade, or cancel anytime. Changes will be effective at the end of your billing cycle."
          }
        />
        <Bullet
          imgSrc={imageBulletLifebuoy}
          imgAlt="Line drawing of a lifebuoy"
          title="Still got questions?"
          description={
            <>
              You can{" "}
              <Link className={styles.fancyLink} to={"/contact"}>
                contact us
              </Link>{" "}
              or{" "}
              <a
                className={styles.fancyLink}
                href={addUtmParams(urlBookADemo, "website", "pricing")}
              >
                book a demo
              </a>
              <br />
              &nbsp;
            </>
          }
        />
      </div>

      <div className={styles.lookingForEnterprise}>
        <div>
          Need more testers, bigger teams, and flexible payment options?
        </div>
        <Link to={"/contact"}>Contact sales for Enterprise solutions</Link>
      </div>
    </div>
  );
}

function Bullet({ imgSrc, imgAlt, title, description }) {
  return (
    <div className={styles.bulletsItem}>
      <img src={imgSrc} alt={imgAlt} />
      <div>
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    </div>
  );
}

function Plan({
  name,
  imageSrc,
  imageAlt,
  currencySymbol,
  price,
  periodLabel,
  buttonHref,
  isMostPopular,
  buttonVariation = "primary",
  buttonText = "Start your free trial",
  children,
  isFreePlan = false,
}) {
  return (
    <div className={styles.plan}>
      <div className={styles.planHeader}>
        <img src={imageSrc} alt={imageAlt} />
        <div>
          <div className={styles.planTitleAndMostPopular}>
            <h2 className={styles.planTitle}>{name}</h2>
            {isMostPopular && (
              <strong className={styles.mostPopular}>Most popular</strong>
            )}
          </div>
        </div>
      </div>
      <div className={styles.planPrice}>
        <span className={styles.planPricePrice}>
          {currencySymbol}
          {price}
        </span>
        <span className={styles.planPricePeriod}>
          {isFreePlan ? "per month" : periodLabel}
        </span>
      </div>
      {buttonVariation === "primary" && (
        <ButtonPrimary
          fullWidth
          className={styles.startFreeTrialButton}
          customTag={"a"}
          href={buttonHref}
        >
          {buttonText}
        </ButtonPrimary>
      )}
      {buttonVariation === "secondary" && (
        <Button
          fullWidth
          className={styles.startFreeTrialButton}
          customTag={"a"}
          href={buttonHref}
        >
          {buttonText}
        </Button>
      )}
      <div className={styles.noCreditCardRequired}>
        No credit card required.
      </div>
      {children}
    </div>
  );
}

function FeaturesContainer({ children }) {
  return (
    <ul className={cn(styles.featureList, styles.mainFeatures)}>{children}</ul>
  );
}

function FeaturesPaid({ collaboratorCount, billingCycle, testersPerMonth }) {
  const items = [
    {
      id: "paid_item_0",
      label: "Test with your own users",
      tooltip:
        "Includes 100 test sessions with your own customers, users, or team members every month. Refills monthly.",
      isChecked: true,
    },
    {
      id: "paid_item_1",
      label: "AI Insights and notes",
      tooltip:
        "Get instant AI Insights to reveal key results from your user tests, based on AI-enhanced transcripts and time-stamped annotations.",
      isChecked: true,
    },
    {
      id: "paid_item_2",
      label: "Highlight clips",
      tooltip:
        "Create short video clips of your key findings and share them with your team and stakeholders.",
      isChecked: true,
    },
    {
      id: "paid_item_3",
      label: "Automated test reports",
      tooltip:
        "Share a comprehensive summary of your tasks, questions, and notes with your team in a flash.",
      isChecked: true,
    },
    {
      id: "paid_item_4",
      label: `${collaboratorCount} collaborator seats`,
      tooltip:
        "Collaborators can create tests and order testers. They can also make notes, create clips, and share test results.",
      isChecked: true,
    },
  ];

  return (
    <FeaturesContainer>
      <FeatureIncludedTesters
        billingCycle={billingCycle}
        testersPerMonth={testersPerMonth}
      />
      {items.map((item) => (
        <Feature key={item.id} {...item} />
      ))}
    </FeaturesContainer>
  );
}

function FeaturesFree() {
  const items = [
    {
      id: "free_item_0",
      label: <strong>Get testers on demand</strong>,
      tooltip:
        "Get testers from our 130k+ pool of qualified testers on demand. No minimum order.",
      isChecked: true,
    },
    {
      id: "free_item_1",
      label: "Test with your own users",
      tooltip:
        "Includes 5 test sessions with your own customers, users, or team members every month. Refills monthly.",
      isChecked: true,
    },
    {
      id: "free_item_2",
      label: "AI Insights and notes",
      isChecked: false,
    },
    {
      id: "free_item_3",
      label: "Highlight clips",
      isChecked: false,
    },
    {
      id: "free_item_4",
      label: "Automated test reports",
      isChecked: false,
    },
    {
      id: "free_item_5",
      label: "No collaborator seats",
      isChecked: false,
    },
  ];

  return (
    <FeaturesContainer>
      {items.map((item) => (
        <Feature key={item.id} {...item} />
      ))}
    </FeaturesContainer>
  );
}

function Feature({ label, tooltip, isChecked = false }) {
  return (
    <li
      className={
        isChecked ? styles.featureList_checked : styles.featureList_notChecked
      }
    >
      {label} {tooltip && <Tooltip>{tooltip}</Tooltip>}
    </li>
  );
}

const FeatureIncludedTesters = ({ billingCycle, testersPerMonth }) => {
  if (billingCycle === "annual") {
    return (
      <li className={styles.featureList_checked}>
        <strong>{testersPerMonth * 12} testers</strong> /{" "}
        <span style={{ whiteSpace: "nowrap" }}>
          <strong>year included</strong>{" "}
          <Tooltip>
            You get {testersPerMonth * 12}&nbsp;testers each year from our{" "}
            {advertedPoolSize}&nbsp;pool of qualified testers. All testers are
            available upfront. Unused testers roll over until you cancel.
          </Tooltip>
        </span>
      </li>
    );
  } else {
    return (
      <li className={styles.featureList_checked}>
        <strong>
          {testersPerMonth} testers /{" "}
          <span style={{ whiteSpace: "nowrap" }}>
            mo. included{" "}
            <Tooltip>
              You get {testersPerMonth}&nbsp;testers each month from our{" "}
              {advertedPoolSize}&nbsp;pool of qualified testers. Unused testers
              roll over until you cancel.
            </Tooltip>
          </span>
        </strong>
      </li>
    );
  }
};
